import '../node_modules/office-ui-fabric-react/dist/css/fabric.min.css';
import './index.css';
import React, { lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import { Route, Routes as Switch } from 'react-router';
import { Routes } from './utils/Routes';
import PageLoading from './components/PageLoading';
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') || '';
const rootElement = document.getElementById('root');

const CreateWebChatRoute = lazy(() => import('./components/CreateWebChatRoute'));
const AppProvider = lazy(() => import('./AppProvider'));

createRoot(rootElement!).render(
  <BrowserRouter basename={baseUrl}>
    <Suspense fallback={PageLoading}>
      <Switch>
        <Route path={Routes.Chat()} element={<CreateWebChatRoute />} />
        <Route path={Routes.ChatWithHash()} element={<CreateWebChatRoute />} />
        <Route path='/*' element={<AppProvider />} />
      </Switch>
    </Suspense>
  </BrowserRouter>);

window.addEventListener("unhandledrejection", function (event) {
  console.warn({ ...event, text: "WARNING: Unhandled promise rejection." });
  event.preventDefault();
});

serviceWorker.unregister();