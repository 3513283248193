export const Routes = {

    Home: () => '/home',
    Plans: () => '/Plans',
    Showcase: () => '/Showcase',

    Bot: (botId?: string) => {
        const baseUrl = `/bot/${botId || ':botId'}`
        return {
            View: () => baseUrl,
            New: () => `/bot/new`,
            Test: (language?: string) => `${baseUrl}/test/web/${language || ':language'}`,
            Tokens: () => ({
                List: () => `${baseUrl}/tokens`,
                New: () => `${baseUrl}/tokens/new`,
            }),
            Features: () => ({
                List: () => `${baseUrl}/features`,
                View: (featureId?: string) => `${baseUrl}/feature/${featureId || ':featureId'}`,
                New: () => `${baseUrl}/feature/new`,
            }),
            Feature: (featureId?: string) => {
                const featureUrl = `${baseUrl}/feature/${featureId || ':featureId'}`

                return {
                    Connectors: () => ({
                        // GitHub: (botId, featureId, connectionId) => `/connect/GitHub/${botId || ':botId'}/${featureId || ':featureId'}/${connectionId || ':connectionId'}`
                        GitHub: () => `${featureUrl}/connect/GitHub`,
                    })
                };

            }
        }
    },
    Chat: (botId?: string, accessToken?: string) => `/chat/${botId || ':botId'}/${accessToken || ':accessToken'}`,
    ChatWithHash: (botId?: string) => `/C/${botId || ':botId'}`,
    Authorize: (connectorName: 'GitHub') => `/Connectors/${connectorName}/Authorize`
};

